<template>
  <div id="receipts">
    <v-container>
      <v-row justify="start">
        <v-col cols="12" md="4" class="mt-3">
          <v-btn
            style="float: left"
            outlined
            small
            :color="$store.state.secondaryColor"
            @click="goBack"
          >
            <v-icon small left>mdi-arrow-left</v-icon>
            <span class="caption">{{ $t("back") }}</span>
          </v-btn>
        </v-col>
      </v-row>
      <v-row justify="end" class="mb-1">
        <v-col cols="12" md="4" class="text-right">
          <v-btn dark small class="gradients" @click="printPage()">
            <v-icon small left>mdi-download</v-icon>
            {{ $t("download") }}
          </v-btn>
        </v-col>
      </v-row>

      <div v-if="pleaseWait" class="mt-3">
        <PleaseWait></PleaseWait>
      </div>
      <!-- ####################### NEW RECEIPT ##################### -->
      <div v-else>
        <div v-show="receipt">
          <v-card class="grey lighten-3">
            <!-- ################## -->
            <div class="body" id="print" style="padding: 30px">
              <div class="container">
                <div class="row center">
                  <div class="column">
                    <span class="bold-text">{{
                      getBusinessName(receipt.businessId)
                    }}</span>
                  </div>
                </div>
                <hr class="white-hr" />
                <div class="row center">
                  <div class="column">
                    <span class="">{{ building }}</span>
                  </div>
                </div>
                <hr class="white-hr" />
                <div class="row center">
                  <div class="column">
                    <span class="">{{ floor }}</span>
                  </div>
                </div>
                <hr class="white-hr" />
                <div class="row center">
                  <div class="column">
                    <span class="">{{ street }}</span>
                  </div>
                </div>
                <hr class="white-hr" />
                <div class="row center">
                  <div class="column">
                    <span class="">{{ county + " " + country }}</span>
                  </div>
                </div>
                <hr class="white-hr" />
                <div class="row center">
                  <div class="column">
                    <span class="bold-text">{{
                      formatDate(receipt.dateTime.toDate())
                    }}</span>
                  </div>
                </div>
                <hr class="white-hr" />
                <div class="row center">
                  <div class="column">
                    <span class="">{{
                      formatDateMinuteTime(receipt.dateTime.toDate())
                    }}</span>
                  </div>
                </div>
                <hr class="white-hr" />

                <hr class="black-hr" />
                <hr class="black-hr" />
                <!-- ####Product titles##### -->

                <div class="row left black-background" style="margin-top: 10px">
                  <div class="column-quarter">
                    <span class="white-text">{{ $t("product name") }}</span>
                  </div>
                  <div class="column-three-quarter">
                    <div class="row">
                      <div class="column-quarter">
                        <span class="white-text">{{ $t("quantity") }}</span>
                      </div>
                      <div class="column-quarter">
                        <span class="white-text">{{ $t("price") }}</span>
                      </div>
                      <div class="column-quarter">
                        <span class="white-text">{{ $t("discount") }}</span>
                      </div>
                      <div class="column-quarter right">
                        <span class="white-text">{{ $t("total") }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- ########## -->
                <!-- ######Product list########## -->
                <div
                  v-for="index in receipt.products"
                  :key="index.id"
                  style="margin-top: 10px"
                >
                  <div class="row left">
                    <div class="column-quarter">
                      <span class="bold-text">{{ index.item.name }}</span>
                    </div>
                    <div class="column-three-quarter">
                      <div class="row">
                        <div class="column-quarter">
                          <span class="">{{ index.qty }}</span>
                        </div>
                        <div class="column-quarter">
                          <span class="">{{ index.price }}</span>
                        </div>
                        <div class="column-quarter">
                          <span class="">{{ index.item.price.discount }}</span>
                        </div>
                        <div class="column-quarter right">
                          <span class="bold-text">{{ index.total }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr class="black-hr" />
                </div>
                <!-- ############## -->

                <!-- #####Totals section### -->

                <div class="row left">
                  <div class="column-quarter">
                    <span class="bold-text">{{ $t("subTotal") }}</span>
                  </div>
                  <div class="column-three-quarter">
                    <div class="row right">
                      <div class="column-half">&nbsp;</div>
                      <div class="column-half">
                        <span class="bold-text">{{
                          currencyFormat(receipt.totalAmount - receipt.vat)
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <hr class="white-hr" />
                <div class="row left">
                  <div class="column-quarter">
                    <span class="bold-text">{{ $t("V.A.T") }}</span>
                  </div>
                  <div class="column-three-quarter">
                    <div class="row right">
                      <div class="column-half">&nbsp;</div>
                      <div class="column-half">
                        <span class="bold-text">{{
                          currencyFormat(receipt.vat)
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <hr class="white-hr" />
                <div class="row left">
                  <div class="column-quarter">
                    <span class="bold-text">{{ $t("total") }}</span>
                  </div>
                  <div class="column-three-quarter">
                    <div class="row right">
                      <div class="column-half">&nbsp;</div>
                      <div class="column-half">
                        <span class="bold-text">{{
                          currencyFormat(receipt.totalAmount)
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <hr class="black-hr" />
                <!-- ########### -->

                <!-- #####Amount paid and change########## -->
                <div class="row left">
                  <div class="column-quarter">
                    <span class="">{{ $t("amount paid") }}</span>
                  </div>
                  <div class="column-three-quarter">
                    <div class="row right">
                      <div class="column-half">&nbsp;</div>
                      <div class="column-half">
                        <span class="bold-text">{{
                          currencyFormat(receipt.amountPayed)
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <hr class="white-hr" />
                <div class="row left">
                  <div class="column-quarter">
                    <span class="">{{ $t("change") }}</span>
                  </div>
                  <div class="column-three-quarter">
                    <div class="row right">
                      <div class="column-half">&nbsp;</div>
                      <div class="column-half">
                        <span class="bold-text">{{
                          currencyFormat(receipt.change)
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <hr class="black-hr" />
                <hr class="black-hr" />
                <!-- ########### -->
                <!-- #####Served by###### -->
                <div class="row center">
                  <div class="column">
                    <span class=""
                      >{{ $t("you were served by") }}
                      {{ receipt.cashier }}</span
                    >
                  </div>
                </div>

                <hr class="black-hr" />
                <!-- ########### -->
                <!-- #####Served by###### -->
                <div class="row center" style="margin-top: 5px">
                  <div class="column">
                    <barcode
                      v-bind:value="receipt.receiptBarCode"
                      format="CODE39"
                      height="19"
                      width="1.5"
                      fontSize="12"
                      font="Courier"
                      options="{
                    'Short barcode'
                    }"
                    >
                      Show this if the rendering fails.
                    </barcode>
                  </div>
                </div>

                <hr class="black-hr" />
                <!-- ########### -->
                <!-- #####Served by###### -->
                <div class="row center">
                  <div class="column">
                    <span class="bold-text">{{
                      $t("thank you for doing business with us")
                    }}</span>
                  </div>
                </div>
                <hr class="black-hr" />
                <!-- ########### -->
                <!-- #####Served by###### -->
                <div class="row center">
                  <div class="column">
                    <span class="">{{ $t("by upsell from mijinitech") }}</span>
                  </div>
                </div>
                <!-- ###### -->
              </div>
            </div>
            <!-- ################## -->
          </v-card>
        </div>
      </div>
      <!-- ############################### -->

      <v-snackbar
        v-model="snackError"
        top
        center
        color="red lighten-5 error--text text--darken-3"
        :timeout="4000"
      >
        <span>{{ $t("could not get receipt details.Please try later") }}.</span>

        <v-btn icon color="error darken-3" @click="snackError = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
import { printer } from "@/plugins/functions";
import { startOfMonth, format, endOfMonth, getMonth } from "date-fns";
import PleaseWait from "@/components/templates/PleaseWait";
import db from "@/plugins/fb";

export default {
  components: {
    PleaseWait,
  },
  data: () => ({
    selectedBusiness: "",
    selectedMonth: "",
    sales: [],
    receipt: "",
    monthSales: [],

    building: "",
    floor: "",
    county: "",
    country: "",
    street: "",
    barcodeValue: "123456789012",

    pleaseWait: true,
    snackError: false,

    detailedReport: [],
  }),

  computed: {
    ownersBusinesses() {
      return this.$store.getters.getBusinesses;
    },
    months() {
      return this.$store.getters.getMonths;
    },
    ownersBusinessIds() {
      return this.$store.getters.getBusinessId;
    },

    title() {
      return "sales receipt";
    },
    reportHeader() {
      return [
        { title: this.$t("business name"), value: "" },
        { title: this.$t("email"), value: "" },
        { title: this.$t("date of report"), value: "" },
        { title: this.$t("report period"), value: "" },
      ];
    },

    dataSummary() {
      return [{ title: this.$t("total sales"), value: "" }];
    },
    currentBusiness() {
      return this.$store.getters.getCurrentBusiness;
    },
    today() {
      return format(new Date(), "yyyy-MM-dd");
    },

    selectedBusinessName() {
      let selected = this.ownersBusinesses.find(
        (item) => item.id == this.selectedBusiness
      );
      return selected.businessName;
    },

    selectedBusinessEmail() {
      let selected = this.ownersBusinesses.find(
        (item) => item.id == this.selectedBusiness
      );
      return selected.email;
    },
  },

  created() {
    this.getSales();
  },

  methods: {
    printer,
    goBack() {
      window.history.back();
    },

    currencyFormat(val) {
      return this.$store.state.currency.symbol + " " + val;
    },

    getSales() {
      db.collection("receipts")
        .doc(this.$route.params.id)
        .get()
        .then((doc) => {
          this.receipt = doc.data();
          this.pleaseWait = false;
        })
        .catch((error) => {
          this.pleaseWait = false;
          this.snackError = true;
          console.log("Error getting  document:", error);
        });

      // this.currentMonthSales(this.sales);
      // this.onCurrentBusiness();
    },

    //function called when month is changed
    onSelectMonth() {
      //pass the selected month to a filter function
      this.monthFilteredSales(this.selectedMonth);
    },

    setReportDates() {
      this.reportHeader[2].value = this.today;
      let startDate = format(startOfMonth(new Date()), "yyyy-MM-dd");
      let endDate = format(endOfMonth(new Date()), "yyyy-MM-dd");
      this.reportHeader[3].value = startDate + " - " + endDate;
    },
    currentMonthSales(arr) {
      let currentMonth = getMonth(new Date());
      let monthSales = arr.filter(
        (item) => getMonth(item.dateTime.toDate()) == currentMonth
      );
      this.monthSales = monthSales;
    },

    //create a function to feed data according to set month mm is the month
    monthFilteredSales(mm) {
      let currentMonth = mm;

      //set report dates
      this.reportHeader[2].value = this.today;
      this.reportHeader[3].value = currentMonth;

      let monthSales = this.sales.filter(
        (item) => format(item.dateTime.toDate(), "MMMM") == currentMonth
      );
      //item.dateTime.toDate().getMonth()
      this.monthSales = monthSales.sort((a, b) =>
        a.dateTime < b.dateTime ? 1 : -1
      );

      //summary Data

      this.totalSales(this.monthSales);
      // detailed report data
      this.salesPerDay(this.monthSales);
    },

    salesPerDay(arr) {
      this.detailedReport = arr;
    },

    totalSales(arr) {
      let allAmounts = arr.map((item) => Number(item.amount));
      let sumAmounts =
        allAmounts.length > 1
          ? allAmounts.reduce((prev, next) => prev + next)
          : allAmounts[0] != null
          ? allAmounts[0]
          : 0;
      this.dataSummary[0].value = this.currencyFormat(sumAmounts);
    },

    // if on business Dashboard
    onCurrentBusiness() {
      let selected = this.ownersBusinesses.find(
        (item) => item.id == this.currentBusiness
      );
      this.reportHeader[0].value = selected.businessName;
      this.reportHeader[1].value = selected.email;
      this.setReportDates();

      // summary data
      this.totalSales(this.monthSales);

      // detailed report data
      this.salesPerDay(this.monthSales);
    },

    // if on general dashboard
    onSelectBusiness() {
      (this.detailedReport = []), (this.dataSummary[0].value = "");
      this.reportHeader[0].value = this.selectedBusinessName;
      this.reportHeader[1].value = this.selectedBusinessEmail;
      this.setReportDates();

      let businessSales = this.monthSales.filter(
        (item) => item.businessId == this.selectedBusiness
      );
      // summary data
      this.totalSales(businessSales);

      // detailed report data
      this.salesPerDay(businessSales);
    },

    getBusinessName(id) {
      let bizId = this.$store.getters.getBusinesses.find(
        (item) => item.id == id
      );

      this.getBusinessLocation(id);
      return bizId.businessName;
    },

    getBusinessLocation(id) {
      let bizId = this.$store.getters.getBusinesses.find(
        (item) => item.id == id
      );

      this.building = bizId.address.building;
      this.floor = bizId.address.no;
      this.county = bizId.address.county;
      this.country = bizId.address.country;
      this.street = bizId.address.street;
    },

    printPage() {
      this.printer("print", "Receipt");
    },
    formatDate(val) {
      return format(val, "yyyy-MM-dd");
    },

    formatDateMinuteTime(val) {
      return format(val, "hh:mm aa");
    },
  },
};
</script>

<style scoped>
.printOut {
  border: 1px solid #eee;
  min-height: 29.7cm;
  width: 21cm;
  padding: 2cm;
  background: #fff;
}
.receiptContents {
  font-family: "Courier New", monospace;
}
.headingRow {
  font-size: 13px;
  color: #aa9494;
  background-color: black;
}
.captionStyle {
  font-size: 13px;
}

.white-hr {
  border: 1px dashed white;
  background-color: white;
}
.black-hr {
  border-top: 1px dashed #000000;
  height: 1px;
  border-radius: 5px;
}
.black-background {
  background-color: #000000;
}
.white-text {
  color: white;
}

.center {
  text-align-last: center;
}
.left {
  text-align-last: left;
}

.right {
  text-align-last: right;
}
/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

.body {
  font-family: "Courier New", monospace;
  padding: 20px;
}
.body .container {
  background: white;
  color: grey (126, 126, 126);
  font-size: 12px;
  padding: 40px;
}
.bold-text {
  font-weight: bolder;
}
.column {
  float: left;
  width: 100%;
  padding: 10px;
  margin-top: 2px;
}

.column-half {
  float: left;
  width: 50%;
  padding: 10px;
}

.column-third {
  float: left;
  width: 33.33%;
  padding: 10px;

  margin-top: 4px;
}

.column-two-third {
  float: left;
  width: 66.66%;
  padding: 10px;
}

.column-quarter {
  float: left;
  width: 25%;
  padding: 10px;
  margin-top: 2px;
}

.column-three-quarter {
  float: left;
  width: 75%;
  padding: 10px;
}
</style>
